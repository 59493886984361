/**
 * Main App Page
 * 
 * If the hunt hasn't started yet, shows the welcome screen.
 * 
 * If the hunt is in progress, shows the game overview, with links to all of the
 * different portions of the game. Also shows progress bars and points earned
 * for each of those portions.
 */

import React from 'react'
import Link from '../components/link'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { getHuntStatus, getTeamCompletedQuestions, isPermanentCode, getCurrentTeamData, getTeamCompletedSecondaryQuestions, getTeamSecondaryQuestionsMeta, getTeamQuestionsMeta } from '../utils/team-data'
import { getSiteData } from '../utils/site'
import LoggedInWrapper from '../components/loggedinWrapper'
import { CompassIllustration } from '../components/images/illustrations'
import SvgArrow from '../components/svg/arrow'
import AppContext from '../utils/context'
import { getCurrentHuntData } from '../utils/hunt-data'
import { getStaticUrl } from '../utils/helpers'

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      cover_image: getStaticUrl(`/images/hunt_cover.jpg`),
      get_started_text: `Ready to get started?`,
      hunt_status: getHuntStatus(),
      num_questions_complete: getTeamCompletedQuestions().length,
    }
  }

  componentDidMount() {
    const data = getCurrentHuntData()
    if (data.hunt !== undefined && data.hunt.cover_photo !== null) {
      this.setState({
        cover_image: data.hunt.cover_photo,
      })
    }
    getSiteData().then(data => {
      if (data.get_started_text !== null) {
        this.setState({
          get_started_text: data.get_started_text,
        })
      }
    })
    this.setState(
      {
        data: data,
        hunt_status: getHuntStatus(),
        num_questions_complete: getTeamCompletedQuestions().length,
      },
      () => {
        try {
          this.setLoading(false)
        } catch {
          console.log(`unable to set loading`)
        }
      }
    )
  }

  render() {
    const { data, num_questions_complete, hunt_status } = this.state
    const is_permanent = isPermanentCode()
    let next_page = is_permanent ? `/rules/` : `/settings/`
    if (data.hunt == undefined) return <LoggedInWrapper />
    const isGnG = data.hunt.hunt_type == 1

    const team = getCurrentTeamData()
    

    let q_meta = getTeamQuestionsMeta()
    let num_questions = q_meta.num_questions
    let q_points = q_meta.score
    let q_possible_points = q_meta.possible_points

    let final_q_meta = getTeamSecondaryQuestionsMeta()
    let num_final_questions = final_q_meta.num_questions
    let final_q_points = final_q_meta.score
    let final_q_possible_points = final_q_meta.possible_points
    let num_final_questions_complete = final_q_meta.num_complete
    
    // check if questions are complete by comparing how many questions there are with the completed ones
    let qCompletedClass = num_questions_complete == num_questions ? `completed` : ``

    // check if there is a maximum amount of points
    if(data.hunt.max_question_points !== undefined && data.hunt.max_question_points > 0){
      // "complete" means if they've gotten the max amount of points
      qCompletedClass = q_points == q_possible_points ? `completed` : ``
    }
    

    let finalQCompletedClass = num_final_questions_complete == num_final_questions ? `completed` : ``

    // mystery message variables
    let mm_points = 0
    let mm_possible_points = data.mystery_message !== undefined ? data.mystery_message.point_value : 15
    if (team.mystery_message_answers && team.mystery_message_answers.points !== undefined) {
      mm_points = team.mystery_message_answers.points
    }
    let mmCompletedClass = mm_points == mm_possible_points ? `completed` : ``

    // emergency message variables
    let em_points = 0
    let em_possible_points = data.emergency_message !== undefined ? data.emergency_message.point_value : 15
    if (team.emergency_message_answers && team.emergency_message_answers.points !== undefined) {
      em_points = team.emergency_message_answers.points
    }
    let emCompletedClass = em_points == em_possible_points ? `completed` : ``

    let indexContent = (
      <div className="pb-5 mb-3">
        <div className="site-sub-header px-4 py-3">
          <div className="row">
            <div className="col text-center">
              <h1 className={`title h5 text-uppercase mb-0`}>Your Game</h1>
            </div>
          </div>
        </div>

        {data.questions !== undefined && data.questions.length > 0 && (
          <div className={`card mx-4 px-1 mt-3`}>
            <div className={`card-body`}>
              {hunt_status == `started` ? (
                <Link to={isGnG ? `/questions/overview/` : `/questions/`} className={`overview-link d-block mb-0`}>
                  <div className="row mb-2">
                    <div className="col-9">
                      <h3 className={`title h6 text-uppercase mb-1`}>{data.hunt.questions_title ? data.hunt.questions_title : `Questions`}</h3>
                    </div>
                    <div className="col-3 text-right arrow-wrap-dark">
                      <SvgArrow />
                    </div>
                  </div>

                  <div className="row no-gutters">
                    <div className="col-8">
                      {data.hunt.max_question_points > 0 ?
                        <ProgressBar
                          className={`${qCompletedClass}`}
                          now={(q_points / q_possible_points) * 100}
                          label={`${q_points}/${q_possible_points}`}
                          srOnly
                        /> 
                        :
                        <ProgressBar
                          className={`${qCompletedClass}`}
                          now={(num_questions_complete / num_questions) * 100}
                          label={`${num_questions_complete}/${num_questions}`}
                          srOnly
                        /> 
                      }
                    </div>
                    <div className="col-4 text-right arrow-wrap-dark align-self-center">
                      <div className={`score`}>
                        {q_points}/{q_possible_points} pts
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                <div className={`overview-link d-block mb-0`}>
                  <div className="row mb-2">
                    <div className="col">
                      <h3 className={`title h6 text-uppercase mb-1`}>{data.hunt.questions_title ? data.hunt.questions_title : `Questions`}</h3>
                    </div>
                  </div>

                  <div className="row no-gutters">
                    <div className="col-8">
                      <ProgressBar
                        className={`${qCompletedClass}`}
                        now={(num_questions_complete / num_questions) * 100}
                        label={`${num_questions_complete}/${num_questions}`}
                        srOnly
                      />
                    </div>
                    <div className="col-4 text-right arrow-wrap-dark align-self-center">
                      <div className={`score`}>
                        {q_points}/{q_possible_points} pts
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {data.secondary_questions !== undefined && data.secondary_questions.length > 0 && (
          <div className={`card mx-4 px-1 mt-3`}>
            <div className={`card-body`}>
              {hunt_status == `started` ? (
                <Link to={`/questions/final/`} className={`overview-link d-block mb-0`}>
                  <div className="row mb-2">
                    <div className="col-9">
                      <h3 className={`title h6 text-uppercase mb-1`}>{data.hunt.secondary_questions_title ? data.hunt.secondary_questions_title : `Final Questions`}</h3>
                    </div>
                    <div className="col-3 text-right arrow-wrap-dark">
                      <SvgArrow />
                    </div>
                  </div>

                  <div className="row no-gutters">
                    <div className="col-8">
                      <ProgressBar
                        className={`${finalQCompletedClass}`}
                        now={(num_final_questions_complete / num_final_questions) * 100}
                        label={`${num_final_questions_complete}/${num_final_questions}`}
                        srOnly
                      />
                    </div>
                    <div className="col-4 text-right arrow-wrap-dark align-self-center">
                      <div className={`score`}>
                        {final_q_points}/{final_q_possible_points} pts
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                <div className={`overview-link d-block mb-0`}>
                  <div className="row mb-2">
                    <div className="col">
                      <h3 className={`title h6 text-uppercase mb-1`}>{data.hunt.secondary_questions_title ? data.hunt.secondary_questions_title : `Final Questions`}</h3>
                    </div>
                  </div>

                  <div className="row no-gutters">
                    <div className="col-8">
                      <ProgressBar
                        className={`${finalQCompletedClass}`}
                        now={(num_final_questions_complete / num_final_questions) * 100}
                        label={`${num_final_questions_complete}/${num_final_questions}`}
                        srOnly
                      />
                    </div>
                    <div className="col-4 text-right arrow-wrap-dark align-self-center">
                      <div className={`score`}>
                        {final_q_points}/{final_q_possible_points} pts
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {hunt_status !== `pending` &&
          data.hunt.enable_answer_sheet !== undefined &&
          data.hunt.enable_answer_sheet && (
          <div className={`card mx-4 px-1 mt-3`}>
            <div className={`card-body`}>
              <Link to={`/answers/`} className={`overview-link d-block mb-0`}>
                <div className="row mb-2">
                  <div className="col-9">
                    <h3 className={`title h6 text-uppercase mb-1`}>
                      {data.hunt.answer_sheet_title ? data.hunt.answer_sheet_title : `Team Answer Sheet`}
                    </h3>
                  </div>
                  <div className="col-3 text-right arrow-wrap-dark">
                    <SvgArrow />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}

        {data.checklists !== undefined && data.checklists.length > 0 && (
          <div ref={`checklists`}>
            {data.checklists.map(checklist => {
              let max_score = checklist.maximum_score
              let cur_score = 0
              if (
                team.checklist_answers !== undefined &&
                team.checklist_answers !== null &&
                team.checklist_answers[checklist.id] !== undefined
              ) {
                cur_score = team.checklist_answers[checklist.id].points
              }
              let completedClass = cur_score == max_score ? `completed` : ``
              return (
                <div key={checklist.id} className={`card mx-4 px-2 mt-3`}>
                  {hunt_status == `started` ? (
                    <Link to={`/checklists/#${checklist.id}`} className={`overview-link d-block card-body`}>
                      <div className="row">
                        <div className="col-9">
                          <h3 className={`title h6 text-uppercase`}> {checklist.display_title}</h3>
                        </div>
                        <div className="col-3 text-right arrow-wrap-dark">
                          <SvgArrow />
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <div className="col-8">
                          <ProgressBar 
                            className={`${completedClass}`} 
                            now={(cur_score / max_score) * 100} 
                            label={`${cur_score}/${max_score} pts`} 
                            srOnly />
                        </div>
                        <div className={`col-4 text-right arrow-wrap-dark`}>
                          <div className={`score`}>
                            {cur_score}/{max_score} pts
                          </div>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <div className={`overview-link d-block card-body`}>
                      <div className="row">
                        <div className="col">
                          <h3 className={`title h6 text-uppercase`}> {checklist.display_title}</h3>
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <div className="col-8">
                          <ProgressBar 
                            className={`${completedClass}`} 
                            now={(cur_score / max_score) * 100} 
                            label={`${cur_score}/${max_score} pts`} 
                            srOnly />
                        </div>
                        <div className={`col-4 text-right arrow-wrap-dark`}>
                          <div className={`score`}>
                            {cur_score}/{max_score} pts
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}

        {hunt_status == `started` &&
          data.hunt.enable_bonus_challenge !== undefined &&
          data.hunt.enable_bonus_challenge && (
          <div className={`card mx-4 px-1 mt-3`}>
            <div className={`card-body`}>
              <Link to={`/bonus-challenge/`} className={`overview-link d-block mb-0`}>
                <div className="row mb-2">
                  <div className="col-9">
                    <h3 className={`title h6 text-uppercase mb-1`}>
                      {data.hunt.bonus_challenge_title ? data.hunt.bonus_challenge_title : `Bonus Challenge`}
                    </h3>
                  </div>
                  <div className="col-3 text-right arrow-wrap-dark">
                    <SvgArrow />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}

        {data.mystery_message !== null && data.mystery_message !== undefined && (
          <div className={`card mx-4 px-1 mt-3`}>
            <div className={`card-body`}>
              {hunt_status == `started` ? (
                <Link to={`/mystery-message/`} className={`overview-link d-block mb-0`}>
                  <div className="row mb-2">
                    <div className="col-9">
                      <h3 className={`title h6 text-uppercase mb-1`}>Mystery Message</h3>
                    </div>
                    <div className="col-3 text-right arrow-wrap-dark">
                      <SvgArrow />
                    </div>
                  </div>

                  <div className="row no-gutters">
                    <div className="col-8">
                      <ProgressBar
                        className={`${mmCompletedClass}`}
                        now={(mm_points / mm_points) * 100}
                        label={`${mm_points}/${mm_possible_points} pts`}
                        srOnly
                      />
                    </div>
                    <div className="col-4 text-right arrow-wrap-dark align-self-center">
                      <div className={`score`}>
                        {mm_points}/{mm_possible_points} pts
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                <div className={`overview-link d-block mb-0`}>
                  <div className="row mb-2">
                    <div className="col">
                      <h3 className={`title h6 text-uppercase mb-1`}>Mystery Message</h3>
                    </div>
                  </div>

                  <div className="row no-gutters">
                    <div className="col-8">
                      <ProgressBar
                        className={`${mmCompletedClass}`}
                        now={(mm_points / mm_points) * 100}
                        label={`${mm_points}/${mm_possible_points} pts`}
                        srOnly
                      />
                    </div>
                    <div className="col-4 text-right arrow-wrap-dark align-self-center">
                      <div className={`score`}>
                        {mm_points}/{mm_possible_points} pts
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {data.emergency_message !== null && data.emergency_message !== undefined && (
          <div className={`card mx-4 px-1 mt-3`}>
            <div className={`card-body`}>
              {hunt_status == `started` ? (
                <Link to={`/emergency-message/`} className={`overview-link d-block mb-0`}>
                  <div className="row mb-2">
                    <div className="col-9">
                      <h3 className={`title h6 text-uppercase mb-1`}>{data.emergency_message.display_title}</h3>
                    </div>
                    <div className="col-3 text-right arrow-wrap-dark">
                      <SvgArrow />
                    </div>
                  </div>

                  <div className="row no-gutters">
                    <div className="col-8">
                      <ProgressBar
                        className={`${emCompletedClass}`}
                        now={(em_points / em_points) * 100}
                        label={`${em_points}/${em_possible_points} pts`}
                        srOnly
                      />
                    </div>
                    <div className="col-4 text-right arrow-wrap-dark align-self-center">
                      <div className={`score`}>
                        {em_points}/{em_possible_points} pts
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                <div className={`overview-link d-block mb-0`}>
                  <div className="row mb-2">
                    <div className="col">
                      <h3 className={`title h6 text-uppercase mb-1`}>{data.emergency_message.display_title}</h3>
                    </div>
                  </div>

                  <div className="row no-gutters">
                    <div className="col-8">
                      <ProgressBar
                        className={`${emCompletedClass}`}
                        now={(em_points / em_points) * 100}
                        label={`${em_points}/${em_possible_points} pts`}
                        srOnly
                      />
                    </div>
                    <div className="col-4 text-right arrow-wrap-dark align-self-center">
                      <div className={`score`}>
                        {em_points}/{em_possible_points} pts
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )

    if (this.state.hunt_status == `pending`) {
      indexContent = (
        <>
          <div
            className={`row text-center justify-content-center align-items-center hunt-cover`}
            style={{
              backgroundColor: `#444`,
              color: `white`,
              backgroundPosition: `center`,
              backgroundSize: `cover`,
              backgroundImage: `url(${this.state.cover_image})`,
              paddingTop: `40px`,
              paddingBottom: `40px`,
            }}>
            <div className={`col-10`} style={{ minHeight: `90px` }}>
              <h2 className={`text-center text-uppercase h2 title`}>
                {data.hunt.display_title.split(`\n`).map((item, key) => (
                  <span key={key} style={{ display: `block` }}>
                    {item}
                  </span>
                ))}
              </h2>
            </div>
          </div>
          <div
            className={`mx-auto`}
            style={{
              marginTop: `-45px`,
              position: `absolute`,
              left: 0,
              right: 0,
              zIndex: 10,
            }}>
            <CompassIllustration />
          </div>

          <div
            className={`card mx-4`}
            style={{
              paddingTop: `100px`,
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            }}>
            <div className={`card-body`}>
              <h6 className={`subtitle h3 text-uppercase text-center mb-4 mt-3`}>{this.state.get_started_text}</h6>

              {data.hunt.enable_qr_code !== undefined && data.hunt.enable_qr_code && (
                <div>
                  <h6 className={`h6 mb-3 text-center`}>Share this QR code with your teammates:</h6>
                  <div className={`text-center`} dangerouslySetInnerHTML={{ __html: team.qr_code }} />
                  <p className="text-center">
                    Or go to play.watsonadventures.com and enter{` `}
                    <span className="h5 fw-bold font-weight-bold">{team.access_code}</span>.
                  </p>
                </div>
              )}

              <div className={`mx-0`}>
                <Link className={`btn btn-block btn-secondary btn-arrow py-3 my-3`} to={next_page}>
                  Continue
                </Link>
              </div>
            </div>
          </div>
        </>
      )
    }

    return (
      <AppContext.Consumer>
        {({ setLoading }) => {
          this.setLoading = setLoading
          return <LoggedInWrapper>{indexContent}</LoggedInWrapper>
        }}
      </AppContext.Consumer>
    )
  }
}
